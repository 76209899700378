<template>
  <div class="activate-account">
    <h2>Hooray Sam!</h2>
    <p>
      You're almost there! Just one click away <br />
      to activate your Glocal account.
    </p>
    <div class="image"/>
  </div>
</template>

<style lang="scss" scoped>
.activate-account {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-size: 8rem;
    font-family: $font-secondary-bold;
    margin: 0 2rem 2rem 2rem;
    position: relative;
  }

  h2::before {
    content: "";
    width: 8rem;
    height: 0.6rem;
    position: absolute;
    left: calc(50% - 2.5rem);
    bottom: 0;
    background-color: #0033dd;
  }

  p {
    font-size: 4rem;
    font-family: $font-secondary-bold;
    margin: 0 2rem;
    text-align: center;
    line-height: 5rem;
    opacity: 0.7;
  }

  .image {
    height: 40rem;
    margin-top: 4rem;
  }
}
</style>